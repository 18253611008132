.pad {
    padding: 75px 0;
}

.logos, .logo {
    display: flex;
    flex-wrap: wrap;
}

.logo {
    width: 20%;
    height: 220px;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
}

.logo img {
    max-height: 150px;
    width: auto;
    max-width: 100%;
}

.col {
    width: 100%;
}

@media(max-width: 960px) {
    .logo {
        width: 25%;
        height: auto;
        margin-top: 30px;
    }
    .pad {
      padding-top: 45px;
    }
}

@media(max-width: 768px) {
    .logo {
        width: 33.3333%;
    }
}

@media(max-width: 576px) {
    .logo {
        width: 50%;
    }
}

@media(max-width: 450px) {
   .logo {
       max-width: 300px;
       width: 100%;
       border-right: 0 !important;
       margin: 0 auto;
       margin-top: 30px;
   }
}
